import React from "react"
import { Link } from "gatsby"

import Chevron from "../../assets/chevron.svg"

const Card = ({
  children,
  theme = "default",
  link = false,
  linkText = null,
}) => {

  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        className="card__outer card-has-link"
        onClick={() => {
          //console.log(linkText);
          if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: "linkOrButtonClick",
              link_url: `${link}`,
              link_text: `${linkText}`,
              location: "",
            })
          }
        }}
      >
        <div className={`card card--theme-${theme}`}>
          <div className="card__inner">{children}</div>
          <div className="card__fake-clickable-area">
            <span className="card__square-shape">
              <Chevron />
            </span>
          </div>
        </div>
      </a>
    )
  }
  return (
    <div className={`card card--theme-${theme} card-no-link`}>
      <div className="card__inner">{children}</div>
    </div>
  )
}

export default Card
