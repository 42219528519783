import React from "react"

import BrandywineGlobal from "../../assets/clients/brandywine-global.svg"
import ClarionPartners from "../../assets/clients/clarion-partners.svg"
import ClearBridgeInvestments from "../../assets/clients/clearbridge-investments.svg"
import K2Advisors from "../../assets/clients/k2-advisors.svg"
import MartinCurrie from "../../assets/clients/martin-currie.svg"
import WesternAsset from "../../assets/clients/western-asset.svg"
import BSP from "../../assets/clients/bsp.svg"
import Royce from "../../assets/clients/royce.svg"
import FT from "../../images/franklin-templeton.png"

const Logo = ({ logo = null }) => {
  if (logo === "clarion-partners") {
    return <ClarionPartners />
  }

  if (logo === "brandywine-global") {
    return <BrandywineGlobal />
  }

  if (logo === "clearbridge-investments") {
    return <ClearBridgeInvestments />
  }

  if (logo === "k2-advisors") {
    return <K2Advisors />
  }

  if (logo === "martin-currie") {
    return <MartinCurrie />
  }

  if (logo === "western-asset") {
    return <WesternAsset />
  }

  if (logo === "bsp") {
    return <BSP />
  }

  if (logo === "royce") {
    return <Royce />
  }

  if (logo === 'franklin-templeton') {
    return <img className="franklin-templeton-card" src={`${FT}`} loading="lazy" />
  }

  return null
}

export default Logo
