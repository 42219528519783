import * as React from "react"
import Partnership from "../sections/partnership"
import Change from "../sections/change"
import Sustainability from "../sections/sustainability"
import Clients from "../sections/clients"
import Local from "../sections/local"

const FlexibleContent = ({ order, content }) => {
  return (
    <>
      {order?.map((sectionName, index) => (
        <ContentFactory component={sectionName} content={content[`${sectionName}`]} key={index} />
      ))}
    </>
  );
};


const ContentFactory = ({ component, content }) => {
  switch (component) {
    case 'partnership':
      return <Partnership content={content} />;
      break;
    case 'change':
      return <Change content={content} />;
      break;
    case 'sustainability':
      return <Sustainability content={content} />;
      break;
    case 'clients':
      return <Clients content={content} />;
    case 'local':
      return <Local content={content} />;
      break;
    default:
      return <></>;
  }
};

export default FlexibleContent;