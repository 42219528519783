import React, { useState, useEffect } from "react"
import { Jump } from "react-jump"
import { useWindowSize } from "@react-hook/window-size"

import lax from "lax.js"
import HelloProgress from "../assets/hello-progress.svg"

import shapeBackground from "../images/body-bg.jpg"
import bgGradient from "../images/bg-gradient.jpg"
import ArrowDown from "./../assets/arrow-down.svg"
import HeroContent from "./blocks/hero-content"

const HeroMasking = ({ content, video }) => {
  const GRID_SIZE = 1060
  const [windowWidth, windowHeight] = useWindowSize()
  const [pixelsFromEdge, setPixelsFromEdge] = useState(0)

  useEffect(() => {
    putHeroInGrid()

    const HPwrapper = document.querySelector(".hello-progress-wrapper")
    const shapeLower = document.querySelector(".hero__shape--lower")
    const shapeUpper = document.querySelector(".hero__shape--upper")
    const loader = document.querySelector(".js-loader")

    HPwrapper.classList.add("is-visible")
    shapeLower.classList.add("is-visible")
    shapeUpper.classList.add("is-visible")

    setTimeout(() => {
      if (loader) {
        loader.classList.add("is-hidden")
      }
    }, 300)

    setTimeout(() => {
      shapeLower.classList.remove("has-transition")
      shapeUpper.classList.remove("has-transition")
    }, 500)

    window.addEventListener("resize", () => {
      putHeroInGrid()
    })
  })

  const putHeroInGrid = () => {
    if (windowWidth > GRID_SIZE) {
      const calculateLeftMargin = (window.innerWidth - 1060) / 2
      setPixelsFromEdge(`${calculateLeftMargin}`)
    } else {
      setPixelsFromEdge(0)
    }
  }

  return (
    <>
      <div>
        <div className="hero" aria-hidden="true">
          <div className="hero__shape--lower"></div>
          <svg className="hero__svg" width="100%" height="100%">
            <defs>
              <mask id="mask">
                <rect width="100%" height="100%" fill="#fff"></rect>
                <defs>
                  <pattern
                    id="foreground"
                    width="100%"
                    height="100%"
                    fill="#000"
                  >
                    <image
                      xlinkHref={`${bgGradient}`}
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                      fillOpacity="1"
                      preserveAspectRatio="xMidYMid slice"
                    ></image>
                  </pattern>
                  <polygon
                    id="path-1"
                    points="0 0 258 0 258 340 0 340"
                  ></polygon>
                  <path
                    d="M258,244.03908 C258,201.021089 231.352663,175.738115 203.169498,165.231741 C227.772652,156.157512 252.373414,129.905874 252.373414,93.0978196 C252.373414,35.7881568 209.819969,0 142.080855,0 L0,0 L0,340 L147.731363,340 C212.402409,340 258,306.613845 258,244.03908"
                    id="path-3"
                  ></path>
                </defs>

                <g className="hello-progress__outer">
                  <g
                    className="hello-progress-wrapper"
                    style={{
                      transform: `translate(${pixelsFromEdge}px, 0)`,
                    }}
                  >
                    <HeroContent country={`${process.env.GATSBY_REGION}`} />
                  </g>
                </g>
              </mask>
            </defs>
            <rect
              width="100%"
              height="100%"
              fill="url(#foreground)"
              mask="url(#mask)"
            ></rect>
          </svg>
        </div>

        <div className="screen-reader-text">
          <h1>{content.heading}</h1>
          <p>{content.subheading}</p>
          <p>{content.cta}</p>
        </div>

        <div className="hero__shape--upper"></div>
      </div>
    </>
  )
  //   return (
  //     <div classname="hero__outer">
  //       <div className="fullheight hero u-v-centered">
  //         <div className="grid grid__container">
  //           <div className="hello-progress">
  //             <HelloProgress />
  //             <div className="hero__subheading">
  //               {content?.subheading}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="shape" style={{ backgroundImage: `url(${shapeBackground})` }}></div>
  //     </div>
  //   )
}

export default HeroMasking
