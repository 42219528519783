import React from "react"

import IntroText from "../components/blocks/introText"
import Card from "../components/blocks/card"

const Partnership = ({ content = null }) => {
  if (!content) {
    return null
  }

  return (
    <section id="partnership" className="regular-section partnership-section">
      <div className="grid grid__container">
        <div className="grid__cell grid__cell--7@medium">
          <IntroText content={content} section="Going beyond" />
        </div>
      </div>

      {content?.cards && (
        <ul className="[ grid grid__container grid--gap-regular ] u-plain-list card-wrapper">
          {content?.cards.map((item, index) => {
            const cardText = item.title ? item.title : `card #${index + 1}`
            return (
              <li className="grid__cell grid__cell--4@medium" key={index}>
                <Card
                  link={item.link}
                  linkText={`Going Beyond - ${cardText}`}
                >
                  <h3 className="card__title">{item.title}</h3>
                  <p>{item.description}</p>
                </Card>
              </li>
            )
          })}
        </ul>
      )}
    </section>
  )
}

export default Partnership
