import React, { useEffect } from "react"
import Swiper, { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import ReactPlayerLoader from "@brightcove/react-player-loader"

import IntroText from "../components/blocks/introText"
import Card from "../components/blocks/card"
import Icon from "../components/blocks/icon"

const Change = ({ content = null }) => {
  const contentHeadingOnly = {
    heading: content.heading,
  }

  const contentWithoutHeading = content
  contentWithoutHeading.heading = null

  useEffect(() => {
    console.log(process.env.GATSBY_BRIGHTCOVE_OR_VIDEO_ID, "cvid")
    Swiper.use([Navigation])
    const swiper = new Swiper(".change-section-swiper", {
      loop: true,
      slidesPerView: 1.2,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        720: {
          slidesPerView: 2.2,
        },
        1300: {
          slidesPerView: 3.4,
        },
      },
    })
  })

  if (!content) {
    return null
  }

  return (
    <>
      {process.env.GATSBY_BRIGHTCOVE_OR_VIDEO_ID && (
        <div className="video__outer" id="video__outer">
          <div className="u-relative video-section video-section--extra-padding">
            <div className="grid grid__container">
              <div className="grid__cell grid__cell--6@medium">
                <div>
                  <p className="u-text-bigger u-text-white u-text-wrapper u-text-18">
                    {content.videoHeadng}
                  </p>
                </div>
              </div>

              <div className="grid__cell grid__cell--9@medium grid__cell--start-3@medium">
                <div className="brightcove-wrapper">
                  <ReactPlayerLoader
                    accountId={`${process.env.GATSBY_BRIGHTCOVE_OR_ACCOUNT}`}
                    videoId={`${process.env.GATSBY_BRIGHTCOVE_OR_VIDEO_ID}`}
                    playerId={`${process.env.GATSBY_BRIGHTCOVE_OR_PLAYER}`}
                    onFailure={error => {
                      console.log(error)
                    }}
                    onSuccess={success => {
                      success.ref.on("play", () => {
                        if (typeof window !== "undefined") {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            videoCategory: "Brightcove Player",
                            videoAction: "Video Played (OR)",
                            videoLabel: `${process.env.GATSBY_BRIGHTCOVE_OR_VIDEO_ID}`,
                            event: "videoEvent",
                          })
                        }
                      })

                      success.ref.on("ended", () => {
                        if (typeof window !== "undefined") {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            videoCategory: "Brightcove Player",
                            videoAction: "Video Ended (OR)",
                            videoLabel: `${process.env.GATSBY_BRIGHTCOVE_OR_VIDEO_ID}`,
                            event: "videoEvent",
                          })
                        }
                      })

                      if (typeof window !== "undefined") {
                        window.dataLayer = window.dataLayer || []
                        window.dataLayer.push({
                          videoCategory: "Brightcove Player",
                          videoAction: "Video Load (OR))",
                          videoLabel: `${process.env.GATSBY_BRIGHTCOVE_OR_VIDEO_ID}`,
                          event: "videoEvent",
                        })
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <section id="change" className="regular-section">
        <div className="grid grid__container">
          <div className="grid__cell grid__cell--6@medium">
            <IntroText content={contentHeadingOnly} />
          </div>
          <div className="grid__cell grid__cell--6@medium">
            <IntroText
              content={contentWithoutHeading}
              section="New fashioned"
            />
          </div>
        </div>

        {content?.cards && (
          <div className="change-section-swiper__outer">
            {content.cardsHeading && (
              <p className="intro-text__subheading u-text-white u-mb-80@desktop u-text-bigger u-text-maxwidth">
                {content.cardsHeading}
              </p>
            )}
            <div className="swiper change-section-swiper">
              <div className="swiper-wrapper">
                {content?.cards.map((item, index) => (
                  <div className="swiper-slide" key={index}>
                    <Card>
                      <div className="card__media">
                        <Icon icon={`${item.icon}`} />
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.description.split("\n").join("<br>"),
                        }}
                      />
                      {/* <p>{item.description}</p> */}
                    </Card>
                  </div>
                ))}
              </div>

              <div className="change-navigation-wrapper">
                <button
                  className="swiper-button-prev"
                  onClick={() => {
                    if (typeof window !== "undefined") {
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        event: "carousel_click",
                        name: "New fashioned - slider",
                        action: "prev",
                      })
                    }
                  }}
                >
                  <Icon icon="arrow-left" />
                </button>
                <button
                  className="swiper-button-next"
                  onClick={() => {
                    if (typeof window !== "undefined") {
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        event: "carousel_click",
                        name: "New fashioned - slider",
                        action: "next",
                      })
                    }
                  }}
                >
                  <Icon icon="arrow-right" />
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default Change
