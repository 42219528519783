import React, { useState, useEffect } from "react"
import ReactPlayerLoader from "@brightcove/react-player-loader"

import lax from "lax.js"
import HelloProgress from "../assets/hello-progress.svg"
import shapeBackground from "../images/bg.jpg"

const Video = ({ content, video }) => {
  return (
    <div className="video__outer" id="video__outer">
      <div className="u-relative video-section video-section--extra-padding">
        <div className="grid grid__container">
          <div className="grid__cell grid__cell--6@medium">
            <div>
              <p className="u-text-bigger u-text-white u-text-wrapper">
                {video?.heading}
              </p>
              {process.env.GATSBY_REGION == "fr-ca" && (
                <p className="u-text-white u-text-wrapper u-text-18">
                  Nous jouons un rôle actif dans la construction d'un avenir
                  meilleur - pour nos clients et pour le monde.
                </p>
              )}

              {process.env.GATSBY_REGION == "pt-br" && (
                <p className="u-text-white u-text-wrapper u-text-18">
                  Estamos criando um futuro ainda mais próspero para nossos clientes e o mundo.
                </p>
              )}

              {process.env.GATSBY_REGION == "pt-os" && (
                <p className="u-text-white u-text-wrapper u-text-18">
                  Estamos criando um futuro ainda mais próspero para nossos clientes e o mundo.
                </p>
              )}

              {process.env.GATSBY_REGION == "en-ca" && (
                <p className="u-text-white u-text-wrapper u-text-18">
                  We're playing an active role in shaping a brighter future for
                  our clients and the world.
                </p>
              )}
              {process.env.GATSBY_REGION == "en-os" && (
                <p className="u-text-white u-text-wrapper u-text-18">
                  We're playing an active role in shaping a brighter future for
                  our clients and the world.
                </p>
              )}

              {process.env.GATSBY_REGION == "es-mx" && (
                <p className="u-text-white u-text-wrapper u-text-18">
                  El incansable deseo por mejorar nos ha convertido en la 6ta. compañía independiente del mundo y, al invertir en tecnología, sostenibilidad y nuevas capacidades innovadoras, estamos desempeñando un papel activo en la creación de un futuro más brillante para nuestros clientes. Nunca miramos hacia atrás, nunca nos quedamos quietos y siempre nos ceñimos a nuestra filosofía.
                </p>
              )}

            </div>
          </div>

          <div className="grid__cell grid__cell--9@medium grid__cell--start-3@medium">
            <div className="brightcove-wrapper">
              <ReactPlayerLoader
                accountId={`${process.env.GATSBY_BRIGHTCOVE_ACCOUNT}`}
                videoId={`${process.env.GATSBY_BRIGHTCOVE_VIDEO_ID}`}
                playerId={`${process.env.GATSBY_BRIGHTCOVE_PLAYER}`}
                onFailure={error => {
                  console.log(error)
                }}
                onSuccess={success => {
                  success.ref.on("play", () => {
                    if (typeof window !== "undefined") {
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        videoCategory: "Brightcove Player",
                        videoAction: "Video Played",
                        videoLabel: `${process.env.GATSBY_BRIGHTCOVE_VIDEO_ID}`,
                        event: "videoEvent",
                      })
                    }
                  })

                  success.ref.on("ended", () => {
                    if (typeof window !== "undefined") {
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        videoCategory: "Brightcove Player",
                        videoAction: "Video Ended",
                        videoLabel: `${process.env.GATSBY_BRIGHTCOVE_VIDEO_ID}`,
                        event: "videoEvent",
                      })
                    }
                  })

                  if (typeof window !== "undefined") {
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      videoCategory: "Brightcove Player",
                      videoAction: "Video Load",
                      videoLabel: `${process.env.GATSBY_BRIGHTCOVE_VIDEO_ID}`,
                      event: "videoEvent",
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Video
