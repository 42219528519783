import * as React from "react"
import Puff from "../../assets/puff.svg"

const Loader = () => {
  return (
    <div className="loader js-loader">
      <div className="loader__inner">
          <Puff />
      </div>
    </div>
  )
}

export default Loader
