import React from "react"

const IntroText = ({ color = "white", content = null, section = "" }) => {
  if (!content) {
    return null
  }

  return (
    <div className={`intro-text intro-text--${color}`}>
      <div className="intro-text__inner">
        {content?.heading && (
          <h2
            className="intro-text__heading"
            dangerouslySetInnerHTML={{
              __html: content?.heading.split("\n").join("<br>"),
            }}
          />
        )}
        {content?.subheading && (
          <p className="intro-text__subheading">{content?.subheading}</p>
        )}
        {content?.textBlock && (

           <p className="intro-text__textblock" dangerouslySetInnerHTML={{ __html: content.textBlock.split('\n').join('<br>')}} />
        )}
        {content?.link?.url && content?.link?.anchor && (
          <a
            href={content?.link?.url}
            className="intro-text__link"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              if (typeof window !== "undefined") {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "linkOrButtonClick",
                  link_url: `${content.link.url}`,
                  link_text: `${section} - ${content.link.anchor}`,
                  location: "",
                })
              }
            }}
          >
            {content?.link?.anchor}
          </a>
        )}
      </div>
    </div>
  )
}

export default IntroText
