import React, { useState, useEffect } from "react"

const CustomTracking = () => {
  const breakPoints = [25, 50, 75, 100]
  const firedEvents = []

  const trackScroll = () => {
    const scrolledAmount = calculateScrolledDistance() || 0
    logScrolledProgress(breakPoints, scrolledAmount)
  }

  const calculateScrolledDistance = () => {
    const scrollTop = window.scrollY
    const docHeight = document.body.offsetHeight
    const winHeight = window.innerHeight
    const scrollPercent = scrollTop / (docHeight - winHeight)
    const scrollPercentRounded = Math.round(scrollPercent * 100)
    return scrollPercentRounded
  }

  const logScrolledProgress = (bp, distance) => {
    if (bp.includes(distance) && !firedEvents.includes(distance)) {
      firedEvents.push(distance)
      if (typeof window !== "undefined") {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "scroll_depth",
          action: `${distance}%`,
        })
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", trackScroll)
  })

  return <></>
}

export default CustomTracking
