import React, { useEffect } from "react"
import lax from "lax.js"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Local from "../sections/local"
import Contact from "../sections/contact"
import Footer from "../components/footer"
import FlexibleContent from "../components/flexible-content"
import Hero from "../components/hero"
import HeroMasking from "../components/hero-masking"
import Video from "../components/video"
import Loader from "../components/blocks/loader"
import CustomTracking from "../components/customTracking"
import CustomTrackingJapan from "../components/customTrackingJapan"

const IndexPage = ({ pageContext }) => {
  useEffect(() => {
    lax.init()

    lax.addDriver("scrollY", function () {
      return window.scrollY
    })

    lax.addElements(".dynamic-content", {
      scrollY: {
        opacity: [
          [0, "screenHeight", "screenHeight * 1.4"],
          {
            1000: [0, 1, 1],
            3000: [0, 0, 1],
          },
        ],
      },
    })

    //desktop
    lax.addElements(".hero__shape--lower, .hero__shape--upper", {
      scrollY: {
        translateX: [
          [0, "screenHeight/4", "screenHeight/3"],
          {
            1000: [
              "screenWidth * 0.75",
              "screenWidth * -1",
              "screenWidth * -1.5",
            ],
            3000: ["0", "screenWidth * -1", "screenWidth * -1.5"],
          },
        ],
        translateY: [
          [0, "screenHeight/4"],
          {
            1000: ["0", "screenHeight / 2.5"],
            3000: ["screenHeight / 4 * -2.2", "screenHeight / 4"],
          },
        ],
        scale: [
          [0],
          {
            1000: [2],
            3000: [1],
          },
        ],
        translateZ: [
          [0, "screenHeight/4"],
          ["0", "0"],
        ],
        rotate: [
          [0, "screenHeight/4"],
          {
            1000: [0, 0],
            3000: ["-45", "-125"],
          },
        ],
      },
    })

    lax.addElements(".final-shape", {
      scrollY: {
        translateX: [
          [0, "screenHeight/4", "screenHeight/3", "screenHeight/1.5"],
          {
            1000: [
              "screenWidth * -1.5",
              "screenWidth * -1.5",
              "screenWidth * -1.2",
              "screenWidth / 5",
            ],
            3000: [
              "screenWidth/2 * -1",
              "screenWidth/2 * -1",
              "screenWidth * -1",
              "screenWidth / 5",
            ],
          },
        ],

        translateY: [
          [0],
          {
            1000: ["screenHeight / 2"],
            3000: [0],
          },
        ],

        scale: [
          [
            0,
            "screenHeight/4",
            "screenHeight/3",
            "screenHeight/1.5",
            "screenHeight * 1.45",
          ],
          {
            1000: ["2", "2", "2", "3.5", "6"],
            3000: ["1", "1", "1", "1", "3"],
          },
        ],

        rotate: [
          [0, "screenHeight / 1.5"],
          {
            1000: ["-200", "-180"],
            3000: ["-200", "-85"],
          },
        ],
      },
    })
  }, [])

  const {
    meta,
    homeURL,
    navigation,
    languageSwitcher,
    hero,
    video,
    sectionsOrder,
    sections,
    footer,
    footerNavigation,
    socialMenu,
  } = pageContext.pageContent
  return (
    <Layout homeURL={homeURL} navigation={navigation} languageSwitcher={languageSwitcher}>
      <Seo seo={meta} />
      <Loader />
      <HeroMasking content={hero} video={video} />
      <Video content={hero} video={video} />
      <div className="dynamic-content u-dynamic-content-background">
        <FlexibleContent order={sectionsOrder} content={sections} />
      </div>
      <Footer
        content={footer}
        footerMenuItems={footerNavigation}
        socialMenu={socialMenu}
      />
      <div className="final-shape"></div>
      <CustomTracking />
    </Layout>
  )
}

export default IndexPage
