import React, { useEffect, useState } from "react"
import Swiper, { Navigation } from "swiper"
import { useWindowWidth } from "@react-hook/window-size"
import "swiper/css"
import "swiper/css/navigation"
import ReactPlayerLoader from "@brightcove/react-player-loader"

import IntroText from "../components/blocks/introText"
import Card from "../components/blocks/card"
import Icon from "../components/blocks/logo"
import Logo from "../components/blocks/logo"
import ArrowLeft from "../assets/arrow-left.svg"
import ArrowRight from "../assets/arrow-right.svg"

const Clients = ({ content = null }) => {
  const screenWidth = useWindowWidth()
  const [deviceClass, setDeviceClass] = useState("")

  const headingOnly = {
    heading: content.heading,
  }

  const contentAlt = {
    heading: null,
    subheading: content.subheading,
    textBlock: content.textBlock,
    link: content.link,
  }

  useEffect(() => {
    console.log(process.env.GATSBY_BRIGHTCOVE_OR_VIDEO_ID, "gvid")
    const device = screenWidth > 1100 ? "is-desktop" : "is-mobile"
    setDeviceClass(device)

    Swiper.use([Navigation])
    const swiper = new Swiper(".clients-section-swiper", {
      loop: true,
      slidesPerView: 1.2,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        720: {
          slidesPerView: 2.2,
        },
        1300: {
          slidesPerView: 3.4,
        },
      },
    })
  }, [screenWidth])

  if (!content) {
    return null
  }

  return (
    <section id="clients" className="regular-section clients">
      <div className="grid grid__container">
        <div className="grid__cell grid__cell--6@medium">
          <IntroText content={headingOnly} />
        </div>
        <div className="grid__cell grid__cell--6@medium">
          <IntroText content={contentAlt} section="Bigandsmall" />
        </div>
      </div>

      {content?.cards && (
        <>
          <div className={`clients-section-swiper__outer ${deviceClass}`}>
            <div className="swiper clients-section-swiper">
              <div className="swiper-wrapper">
                {content?.cards.map((item, index) => {
                  let gtmTracking = ""
                  if (item.logo) {
                    gtmTracking = `Bigandsmall - ${item.logo
                      .replace(/-/g, " ")
                      .replace(/(^\w|\s\w)/g, m => m.toUpperCase())}`
                  } else {
                    gtmTracking = `Bigandsmall - ${item.title}`
                  }
                  return (
                    <div className="swiper-slide" key={index}>
                      <Card link={item.url} linkText={gtmTracking}>
                        <div className="card__media">
                          {item.logo && <Logo logo={`${item.logo}`} />}
                          {item.title && (
                            <h2
                              className="card__heading"
                              dangerouslySetInnerHTML={{
                                __html: item.title.split("\n").join("<br>"),
                              }}
                            />
                          )}
                          {/* <Icon icon={`${item.icon}`} /> */}
                        </div>

                        {item.subtitle && (
                          <p className="u-text-bolder u-subheading">
                            {item?.subtitle}
                          </p>
                        )}

                        <p>{item.description}</p>
                      </Card>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className={`client-navigation-wrapper ${deviceClass}`}>
            <button
              className="swiper-button-prev"
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: "carousel_click",
                    name: "Bigandsmall - mobile slider",
                    action: "prev",
                  })
                }
              }}
            >
              <ArrowLeft icon="arrow-left" />
            </button>
            <button
              className="swiper-button-next"
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: "carousel_click",
                    name: "Bigandsmall - mobile slider",
                    action: "next",
                  })
                }
              }}
            >
              <ArrowRight icon="arrow-right" />
            </button>
          </div>
        </>
      )}

      {content?.cards && (
        <ul
          className={`[ grid grid__container grid--gap-regular ] u-plain-list card-wrapper desktop-card-wrapper clients-desktop-card-wrapper ${deviceClass}`}
        >
          {content?.cards.map((item, index) => {
            let gtmTracking = ""
            if (item.logo) {
              gtmTracking = `Bigandsmall - ${item.logo
                .replace(/-/g, " ")
                .replace(/(^\w|\s\w)/g, m => m.toUpperCase())}`
            } else {
              gtmTracking = `Bigandsmall - ${item.title}`
            }
            return (
              <li className="grid__cell grid__cell--4@medium" key={index}>
                <Card link={item.url} linkText={gtmTracking}>
                  <div className="card__media">
                    {item.logo && <Logo logo={`${item.logo}`} />}
                    {item.title && (
                      <h2
                        className="card__heading"
                        dangerouslySetInnerHTML={{
                          __html: item.title.split("\n").join("<br>"),
                        }}
                      />
                    )}
                    {/* <Icon icon={`${item.icon}`} /> */}
                  </div>
                  {item.subtitle && (
                    <p className="u-text-bolder u-subheading">
                      {item?.subtitle}
                    </p>
                  )}
                  <p>{item.description}</p>
                </Card>
              </li>
            )
          })}
        </ul>
      )}
    </section>
  )
}

export default Clients
