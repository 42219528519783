import React from "react"

import IntroText from "../components/blocks/introText"
import Card from "../components/blocks/card"

const Local = ({ content = null }) => {
  const gridSize = process.env.GATSBY_REGION == "en-za" ? 'grid__cell--7@medium' : 'grid__cell--6@medium'
  if (!content) {
    return null
  }

  return (
    <section id="local" className="regular-section local-section u-relative u-background-white">
      <div className="grid grid__container">
        <div className={`grid__cell ${gridSize}`}>
          <IntroText content={content} color="black" />
        </div>
      </div>

      {content?.cards && (
        <ul className="[ grid grid__container grid--gap-regular ] u-plain-list card-wrapper">
          {content?.cards.map((item, index) => {
            const cardText = item.title ? item.title : `card #${index + 1}`
            return (
            <li className="grid__cell grid__cell--4@medium" key={index}>
              <Card link={item.link} theme="grey" linkText={`Local section - ${cardText}`} >
                <h3 className="card__title">{item.title}</h3>
                <p>{item.description}</p>
              </Card>
            </li>
          )
          })}
        </ul>
      )}
    </section>
  )
}

export default Local
