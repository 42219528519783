import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Favicon from '../images/favicon.png';
function Seo({seo}) {
  return (
    <Helmet
      title={seo.title}
      htmlAttributes={{lang: seo.lang}}
        meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.ogTitle,
        },
        {
          property: `og:description`,
          content: seo.ogDescription,
        },
        {
          property: `og:type`,
          content: seo.ogType,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: seo.twitterTitle,
        },
        {
          name: `twitter:description`,
          content: seo.twitterDescription,
        },
      ]}
    >
      <link rel="icon" type="images/png" href={Favicon} />
    </Helmet>
  )
}

export default Seo
