import React from "react"

import IntroText from "../components/blocks/introText"
import { StaticImage } from "gatsby-plugin-image"

const Sustainability = ({ content = null }) => {
  if (!content) {
    return null
  }

  return (
    <section id="sustainability" className="regular-section">
      <div className="grid grid__container">
        <div className="grid__cell grid__cell--7@medium">
          <IntroText content={content} section="Greeeeeen"/>
        </div>
      </div>

      <div className="grid grid__container">
        <div className="grid__cell grid__cell--7@medium grid__cell--start-7@medium">
          <div className="local__media">
          <StaticImage src="../images/butterfly.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Sustainability
